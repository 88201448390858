import React from "react";
import { HiEye, HiEyeOff, HiLockClosed } from "react-icons/hi";

const PasswordField = ({
  title,
  password,
  onChange,
  showPassword,
  setShowPassword,
}) => {
  return (
    <div className="w-full">
      <label
        htmlFor="password"
        className="flex relative text-gray-800"
      >
        <HiLockClosed className="pointer-events-none w-4 h-4 md:w-5 md:h-5 absolute my-4 mx-4 text-gray-700" />
        {showPassword === false ? (
          <HiEyeOff
            className="absolute top-[18px] right-5 cursor-pointer "
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <HiEye
            className="absolute top-[18px] right-5 cursor-pointer "
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
        <input
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          value={password}
          autoComplete="off"
          placeholder={title}
          onChange={onChange}
          className="w-full border-[1px] bg-white focus:border-logo text-sm md:text-sm font-normal focus:outline-none h-12 pl-10 py-0 placeholder:text-xs placeholder:md:text-sm md:pl-10 md:h-14 pr-12 placeholder:text-gray-600"
        />
      </label>
    </div>
  );
};

export default PasswordField;
