import React from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { MyButton } from "./ButtonUtils";

export const ActionButton = ({ props }) => {
  return props.loading ? (
    <button
      type="button"
      className="flex items-center min-w-fit justify-center px-4 py-2 my-10 w-full h-14 md:w-3/12 bg-brand/60 text-white font-mono leading-tight capitalize hover:bg-brand/80 focus:bg-brand/80 focus:shadow focus:outline-none focus:ring-0 active:bg-brand/80 active:shadow transition duration-150 ease-in-out text-center"
    >
      <AiOutlineLoading className="w-7 h-7 animate-spin" />
    </button>
  ) : (
    <button
      type="submit"
      className="text-sm lg:text-base min-w-fit px-4 py-2 my-10 w-full h-14 md:w-3/12 bg-brand/60 text-white font-mono leading-tight capitalize hover:bg-brand/80 focus:bg-brand/80 focus:shadow focus:outline-none focus:ring-0 active:bg-brand/80 active:shadow transition duration-150 ease-in-out text-center"
    >
      {props.title}
    </button>
  );
};

export const MyActionButton = ({ loading, title, onClick, className }) => {
  return (
    <MyButton
      type="submit"
      onClick={onClick}
      disabled={loading ? true : false}
      className={`text-white bg-gray-800 hover:bg-gray-600 font-bold capitalize min-w-fit w-fit px-8 py-3 my-8 text-xs ${className}`}
    >
      {loading ? (
        <span className="px-12 flex justify-center items-center">
          <AiOutlineLoading className="text-base animate-spin" />
        </span>
      ) : (
        <p className="min-w-fit w-full">{title}</p>
      )}
    </MyButton>
  );
};
