import { useState } from "react";
import createDOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";

import { MyButton } from "../../component/ButtonUtils";
import { pushCountry } from "../../../../middlewares/sysNavigation/SysNavigation";
import { useDoSeeDataContext } from "../../../../context/doSeeData/DoSeeDataContext";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { getType } from "../contentStats/helper";

const DOMPurify = createDOMPurify(window);

export const addTextNewLines = (val = "") => {
  return (
    <div
      className="line-clamp-3"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(val) }}
    />
  );
};

export const SeachContent = ({
  data,
  route,
  title,
  idLabel,
  setLocalSearch,
  titleLabel = "title",
  collection,
  pushMethod,
  onDeleteAction,
}) => {
  return (
    <div
      className={
        data && data?.length !== 0 ? "block w-full space-y-2 py-5" : "hidden"
      }
    >
      <p className="text-xl font-bold capitalize">
        {title} <span className="text-sm font-bold">({data?.length})</span>
      </p>
      <div className="flex flex-col space-y-5 border-t border-l border-r border-gray-200 px-3 pt-3">
        {data?.map((item, idx) => (
          <SeachListItem
            key={idx}
            index={idx + 1}
            route={route}
            id={item[`${idLabel}`]}
            title={item[`${titleLabel}`]}
            setLocalSearch={setLocalSearch}
            description={item?.description}
            collection={collection}
            pushMethod={pushMethod}
            content={item}
            onDeleteAction={onDeleteAction}
          />
        ))}
      </div>
    </div>
  );
};

const SeachListItem = ({
  id,
  index,
  title,
  route,
  description,
  setLocalSearch,
  collection,
  pushMethod,
  content,
  onDeleteAction,
}) => {
  const { deleteOneContent } = useDoSeeDataContext();
  const { sysAdmin, setSingleAdmin } = useAdminContext();

  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    const { status } = await deleteOneContent({
      data_id: id,
      content: collection,
    });
    setLoading(false);

    if (status === 200) {
      setLocalSearch(null);
      onDeleteAction();
    }
  };

  const onEdit = () => {
    pushMethod(content);
    pushCountry({
      country: {
        id: 0,
        name: "",
        slug: "",
        route: "/search",
      },
    });
    setSingleAdmin({
      ids: [sysAdmin?.admin_id],
      type: getType({ field: collection }),
      route: "/search",
    });
  };

  return (
    <div
      className={`space-y-2 border-gray-200 p-2 text-gray-900 hover:text-gray-600 border-b`}
    >
      <p className="text-base font-bold">
        {index}. {title}
      </p>

      <div
        className="text-sm capitalize line-clamp-10"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
      ></div>

      <div className="flex items-center space-x-4 border p-2">
        <MyButton onClick={onDelete} className="text-red-700 text-2xl">
          {loading ? (
            <AiOutlineLoading className="animate-spin" />
          ) : (
            <FaTrashAlt />
          )}
        </MyButton>
        <Link to={route} onClick={onEdit}>
          <MyButton className="text-blue-700 text-2xl">
            <FaEdit />
          </MyButton>
        </Link>
      </div>
    </div>
  );
};

export const Placeholder = () => {
  return (
    <div className="flex w-full flex-col space-y-2">
      <div className="h-full w-full animate-pulse cursor-pointer space-y-2">
        <div className="h-12 w-full bg-gray-100" />
        <div className="h-8 w-full bg-gray-100" />
      </div>
      <div className="h-full w-full animate-pulse cursor-pointer space-y-2">
        <div className="h-20 w-full bg-gray-100" />
        <div className="h-10 w-full bg-gray-100" />
      </div>
    </div>
  );
};
