/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { MyButton } from "../../component/ButtonUtils";
import { Pagination } from "../../component/Pagination";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { useDoSeeDataContext } from "../../../../context/doSeeData/DoSeeDataContext";
import { _TYPES_ } from "../../../doseeData/utils/types";
import { Modal } from "../../component/ModalUtils";
import {
  pushCountry,
  pushOneEvent,
} from "../../../../middlewares/sysNavigation/SysNavigation";
import Service from "../../../../api/service";
import { getType } from "../contentStats/helper";
import Preview from "./Preview";

const editRoute = "/data/event/edit";

const Overview = () => {
  const [events, setEvents] = useState(null);
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!events) getEvents(page, pageSize);
  }, [events]);

  const getEvents = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/admin/generated/event/?page=${page}&pageSize=${pageSize}`;
    const { data } = await Service.get(url);
    if (data) {
      setCount(data.count);
      setEvents(data.data);
    }
  };

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 ext-sm text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/3">Image</th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">Title</th>
                <th className="border text-center w-1/3">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {events ? (
                events?.map((evt, key) => (
                  <tr key={key}>
                    <td className="border px-2 lg:px-4 w-1/3 py-2">
                      <img
                        src={evt?.thumbnail}
                        alt={evt?.title}
                        className="w-16 h-16 object-cover rounded-md"
                      />
                    </td>
                    <td className="border px-2 lg:px-4 w-1/3">{evt?.title}</td>
                    <td className="border w-1/3">
                      <ActionButton
                        props={evt}
                        reloadData={() => getEvents(page, pageSize)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(p, size) => {
            getEvents(p, size);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props, reloadData }) => {
  const { sysAdmin, setSingleAdmin } = useAdminContext();
  const isSuperAdmin = ["super_admin", "admin"].includes(sysAdmin?.role);
  const { deleteOneContent, updateOneContent } = useDoSeeDataContext();

  const [showModal, setShowModal] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const event_id = props.event_id;
  const event_title = props.title;
  const event = props;

  const deleteOneEventById = async (event_id) => {
    const { status } = await deleteOneContent({
      data_id: event_id,
      content: _TYPES_.EVENT,
    });

    setShowModal(false);

    if (status === 200) {
      reloadData();
    }
  };

  const suspension = async (event) => {
    const data = {
      country_id: event?.country_id,
      description: event?.description,
      first_level_id: event?.first_level_id,
      fourth_level_id: event?.fourth_level_id,
      second_level_id: event?.second_level_id,
      slug: event?.title?.toLowerCase().replace(/\s/g, "_"),
      status: event?.status === 1 ? 0 : 1,
      third_level_id: event?.third_level_id,
      thumbnail: event?.thumbnail,
      title: event?.title,
      url_images: event?.url_images,
    };

    setSingleAdmin({
      ids: [sysAdmin?.admin_id],
      type: getType({ field: _TYPES_.EVENT }),
      route: "/generated",
    });

    const { status } = await updateOneContent({
      data_id: event?.event_id,
      data: data,
      content: _TYPES_.EVENT,
    });

    if (status === 200) {
      reloadData();
    }
  };

  const onEdit = (event) => {
    pushOneEvent(event);

    pushCountry({
      country: {
        id: 0,
        name: "",
        slug: "",
        route: "/generated",
      },
    });

    setSingleAdmin({
      ids: [sysAdmin?.admin_id],
      type: getType({ field: _TYPES_.EVENT }),
      route: "/generated",
    });
  };

  return (
    <>
      {showPreview ? (
        <Preview
          event={event}
          isSuperAdmin={isSuperAdmin}
          suspension={suspension}
          editRoute={editRoute}
          onEdit={onEdit}
          setShowDeleteModal={setShowModal}
          setShowPreview={setShowPreview}
        />
      ) : null}

      <Modal
        props={{
          name: event_title,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneEventById(event_id),
        }}
      />

      <div className="flex flex-row justify-end items-center px-2 py-3 space-x-4 text-xs">
        {/* {isSuperAdmin ? (
          <>
            <MyButton
              title={event?.status === 1 ? "Make Private" : "Make Public"}
              className={`py-2 px-5 ${
                event?.status === 1
                  ? "bg-gray-900 hover:bg-gray-600"
                  : "bg-red-700 hover:bg-red-600"
              }`}
              onClick={() => suspension(event)}
            />
          </>
        ) : null} */}

        <MyButton
          title="Preview"
          className={`py-2 px-5 bg-black hover:bg-black/80`}
          onClick={() => setShowPreview(true)}
        />

        {/* <Link to={editRoute}>
          <MyButton
            title="Edit"
            className={`py-2 px-5 bg-blue-800 hover:bg-blue-600`}
            onClick={() => onEdit(event)}
          />
        </Link>
        <MyButton
          title="Delete"
          className={`py-2 px-5 bg-red-800 hover:bg-red-600`}
          onClick={() => setShowModal(true)}
        /> */}
      </div>
    </>
  );
};

export default Overview;
