import React from "react";
import { __ROUTES__ } from "./route";
import { BsHouseDoor } from "react-icons/bs";
import { RiServiceLine } from "react-icons/ri";
import { AiOutlineLock, AiOutlinePieChart } from "react-icons/ai";
import {
  FaCity,
  FaUserShield,
  FaPeopleArrows,
  FaPlaceOfWorship,
  FaRobot,
} from "react-icons/fa";
import {
  MdPayment,
  MdOutlineStairs,
  MdAdminPanelSettings,
  MdOutlineBeachAccess,
  MdOutlineBusinessCenter,
  MdSettingsSystemDaydream,
} from "react-icons/md";

const {
  about,
  admin,
  agent,
  banner,
  blog,
  citiesDasb,
  citiesSettings,
  climate,
  company,
  contact,
  contentStats,
  adminsStats,
  countriesDash,
  countriesSettings,
  email,
  ethnicGroup,
  footer,
  home,
  language,
  levelDash,
  levelSettings,
  newsletter,
  page,
  partner,
  plugImage,
  regionDash,
  regionSettings,
  religion,
  service,
  team,
  tradeOrganization,
  regionalBlocks,
  user,
  terms,
  privacy,
  password,
  payment,
  search,
  generatedEvents,
  // currency,
} = __ROUTES__;

export const Windows = [
  {
    title: "Dashboard",
    icon: <BsHouseDoor className="text-lg" />,
    roles: [
      "admin",
      "super_admin",
      "content_admin",
      "content_creator",
      "company_owner",
      "system_admin",
    ],
    menu: [
      { title: "Dashboad", ref: home },
      {
        title: "Global Search",
        ref: search,
        roles: ["admin", "super_admin"],
      },
    ],
  },
  {
    title: "Regions",
    icon: <FaPlaceOfWorship className="text-lg" />,
    roles: ["admin", "super_admin"],
    menu: [
      { title: "Dashboard", ref: regionDash },
      { title: "Manage", ref: regionSettings },
    ],
  },
  {
    title: "Countries",
    icon: <FaPeopleArrows className="text-lg" />,
    roles: [
      "admin",
      "super_admin",
      "content_admin",
      "content_creator",
      "company_owner",
      "system_admin",
    ],
    menu: [
      { title: "Dashboard", ref: countriesDash },
      { title: "Manage", ref: countriesSettings },
    ],
  },
  {
    title: "Our Levels",
    icon: <MdOutlineStairs className="text-lg" />,
    roles: ["admin", "super_admin"],
    menu: [
      { title: "Dashboard", ref: levelDash },
      { title: "Manage", ref: levelSettings },
    ],
  },
  {
    title: "Cities",
    icon: <FaCity className="text-lg" />,
    roles: [
      "admin",
      "super_admin",
      "content_admin",
      "content_creator",
      "company_owner",
      "system_admin",
    ],
    menu: [
      { title: "Dashboard", ref: citiesDasb },
      { title: "Manage", ref: citiesSettings },
    ],
  },
  {
    title: "Generated Events",
    icon: <FaRobot className="text-lg" />,
    roles: [
      "admin",
      "super_admin",
      "content_admin",
      "content_creator",
      "company_owner",
      "system_admin",
    ],
    menu: [{ title: "Manage", ref: generatedEvents }],
  },
  {
    title: "My Statistics",
    icon: <AiOutlinePieChart className="text-lg" />,
    roles: [
      "admin",
      "super_admin",
      "content_admin",
      "content_creator",
      "company_owner",
      "system_admin",
    ],
    menu: [{ title: "My Statistics", ref: contentStats }],
  },
  {
    title: "My Password",
    icon: <AiOutlineLock className="text-lg" />,
    roles: [
      "admin",
      "super_admin",
      "content_admin",
      "content_creator",
      "company_owner",
      "system_admin",
    ],
    menu: [{ title: "Update Password", ref: password }],
  },
  {
    title: "Pages & Blog",
    icon: <RiServiceLine className="text-lg" />,
    roles: ["admin", "super_admin"],
    menu: [
      { title: "Pages", ref: page },
      { title: "Banner Images", ref: banner },
      { title: "Blog", ref: blog },
    ],
  },
  {
    title: "Additional Data",
    icon: <MdOutlineBeachAccess className="text-lg" />,
    roles: ["admin", "super_admin"],
    menu: [
      { title: "Trade Organizations", ref: tradeOrganization },
      { title: "Regional Blocks", ref: regionalBlocks },
      { title: "Plug Images", ref: plugImage },
      { title: "Climates", ref: climate },
      { title: "Ethnic Groups", ref: ethnicGroup },
      { title: "Languages", ref: language },
      { title: "Religions", ref: religion },
    ],
  },
  {
    title: "Admins & Statistics",
    icon: <MdAdminPanelSettings className="text-lg" />,
    roles: [
      "admin",
      "super_admin",
      "content_admin",
      "company_owner",
      "system_admin",
    ],
    menu: [
      { title: "Admins Settings", ref: admin },
      { title: "Admins Statistics", ref: adminsStats },
    ],
  },
  {
    title: "Users / Agents Settings",
    icon: <FaUserShield className="text-lg" />,
    roles: ["admin", "super_admin"],
    menu: [
      { title: "Do see - Agents", ref: agent },
      { title: "Do see - Users", ref: user },
      { title: "Do see - Team", ref: team },
      // { title: "Do see - Newsletter", ref: newsletter },
    ],
  },
  {
    title: "Company Management",
    icon: <MdOutlineBusinessCenter className="text-lg" />,
    roles: ["admin", "super_admin", "company_owner"],
    menu: [{ title: "Manage", ref: company }],
  },
  {
    title: "Edit Sitepages",
    icon: <MdSettingsSystemDaydream className="text-lg" />,
    roles: ["admin", "super_admin"],
    menu: [
      { title: "About Us - Settings", ref: about },
      { title: "Footer - Settings", ref: footer },
      { title: "Contact Us Forms - Settings", ref: contact },
      { title: "Email Templates - Settings", ref: email },
      { title: "Our Service - Settings", ref: service },
      { title: "Our Partners - Settings", ref: partner },
      { title: "Terms & Conditions", ref: terms },
      { title: "Privacy Policy", ref: privacy },
    ],
  },

  {
    title: "Payments",
    icon: <MdPayment className="text-lg" />,
    roles: ["admin", "super_admin", "company_owner", "system_admin"],
    menu: [
      {
        title: "Payments - History",
        ref: payment,
        roles: ["admin", "super_admin", "company_owner", "system_admin"],
      },
    ],
  },
  {
    title: "Subscriptions",
    icon: <MdPayment className="text-lg" />,
    roles: ["admin", "super_admin"],
    menu: [{ title: "Do see - Newsletter", ref: newsletter }],
  },
];
