import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import TextField from "./components/TextField";
import MessageBox from "./components/MessageBox";
import { AiOutlineLoading } from "react-icons/ai";
import { HiInformationCircle } from "react-icons/hi";
import PasswordField from "./components/PasswordField";
import dosee_logo from "../../../assets/images/dosee.png";
import { useAdminContext } from "../../context/admin/context/AdminContext";

const Login = () => {
  const { adminLogin, sysAdmin } = useAdminContext();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [succes, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const responses = (err, message) => {
    setError(err);
    setSuccess(!err);
    setLoading(false);
    setErrorMessage(message);
  };

  const sendLoginRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email || email === "") {
      responses(true, "email address is required");
      return;
    } else if (!password || password === "") {
      responses(true, "password is required");
      return;
    }

    const { message, status } = await adminLogin({
      login: email,
      password: password,
    });
    if (status !== 200 && status !== 201) responses(true, message);
  };

  if (sysAdmin !== null) {
    return (
      <Navigate
        replace
        to="/"
      />
    );
  }

  return (
    <div className="h-screen w-screen overflow-y-auto">
      <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div className="flex-col items-center justify-center col-span-1 h-full bg-logo hidden lg:flex">
          <img
            className="object-cover"
            src={dosee_logo}
            alt="dosee logo"
          />
        </div>
        <div className="flex flex-col mx-auto lg:w-8/12 justify-center items-center w-full h-full col-span-1">
          <form
            onSubmit={sendLoginRequest}
            className="w-full"
          >
            <div className="w-full px-6 lg:px-10 xl:px-20">
              {/* Display on tablets and phones only */}
              <div className="flex flex-col items-center justify-center w-full h-full bg-white lg:hidden mb-8">
                <img
                  className="bg-white outline-none w-5/6"
                  src={dosee_logo}
                  alt="dosee logo"
                />
              </div>
              <h3 className="py-2 text-3xl text-gray-800 text-center font-mono uppercase">
                <b>Admin Portal</b>
              </h3>
              <h4 className="font-mono mt-5 text-base md:text-xl text-gray-800 uppercase">
                Authentication
              </h4>
              <p className="text-left text-xs text-gray-800 select-none">
                Provide your email and password to access your dashboard
              </p>
              <div className="flex flex-col space-y-4 py-5 w-full h-full">
                <TextField
                  value={email}
                  type="email"
                  title="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <PasswordField
                  title="Password"
                  password={password}
                  setError={setError}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError(false);
                    setSuccess(false);
                  }}
                />
                <MessageBox
                  error={error}
                  success={succes}
                  message={message}
                  setError={setError}
                  setSuccess={setSuccess}
                  setErrorMessage={setErrorMessage}
                  onClick={() => {
                    setError(false);
                    setSuccess(false);
                  }}
                />
              </div>
              <div className="flex justify-end items-center my-5 w-full">
                <Link
                  to="/signup"
                  className="text-blue-800 underline text-xs hover:text-blue-600 capitalize font-bold"
                >
                  No Account? Create a company account
                </Link>
              </div>
              <div className="flex justify-start items-center space-x-1">
                <HiInformationCircle className="text-gray-800 text-lg" />
                <p className="text-xs text-gray-800">
                  Always logout when you are away.
                </p>
              </div>
              <div className="my-4">
                <button
                  type={loading ? "button" : "submit"}
                  className="text-white bg-gray-800 w-full py-5 text-xs font-bold flex justify-center items-center"
                >
                  {loading ? (
                    <AiOutlineLoading className="text-sm animate-spin" />
                  ) : (
                    <span>CONNECT</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
